<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="started + loaded">
        <webinarJoin :username="username" v-if="!started" :callback="function(name) {startWebinar(name)}" />
        <flixLoader v-if="started && !loaded" :text="getLoadMsg()"/>
        <div class="flix-form-group" v-if="loaded && started">
          <iframe allow="camera; microphone; fullscreen; display-capture; autoplay" :src="'https://videoserver001.video-concierge.com/meeting-' + webinar.ID"></iframe>
        </div>
        <a v-if="loaded && started" href="#" @click.prevent="closeWebinar" class="flix-btn flix-btn-danger">{{ $t('message.close', {name: $tc('message.webinar', 1)}) }}</a>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    webinarJoin () { return import('@/components/webinars/jitsi/join') }
  },
  props: {
    webinar: Object,
    user: String,
    username: String
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      started: false,
      loaded: false,
      url: '',
      error: false,
      timeout: false
    }
  },
  beforeDestroy () {
    this.clearTimeout()
  },
  methods: {
    closeWebinar () {
      this.started = false
      this.loaded = false
    },
    getLoadMsg () {
      if (this.error) {
        return this.$t('message.webinarWaitingMsg')
      }
      return this.$t('message.load', { name: this.$tc('message.webinar', 1) })
    },
    startWebinar (name) {
      this.loaded = true
      this.started = true
    },
    reloadWebinar (name) {
      this.clearTimeout()
      this.timeout = setTimeout(function () {
        this.joinWebinar(name)
      }.bind(this), 10000)
    },
    joinWebinar (name) {
      this.started = true
      var send = this.webinar
      send.name = name
      this.$flix_post({
        data: send,
        url: 'webinar/bookingflix/attendee_join',
        callback: function (ret) {
          if (ret.data[0]) {
            this.clearTimeout()
            this.error = false
            this.url = ret.data[1].url
            this.loaded = true
          } else {
            this.error = true
            this.started = true
            this.loaded = false
            this.reloadWebinar(name)
          }
        }.bind(this)
      })
    },
    clearTimeout () {
      if (this.timeout !== false) {
        clearTimeout(this.timeout)
      }
    },
    createWebinar (name) {
      this.started = true

      var send = this.webinar
      send.role = this.user
      send.name = name
      send.logoutURL = this.$flix_url + '/webinar-success.html'
      this.$flix_post({
        data: send,
        url: 'webinar/bookingflix/admin_join',
        callback: function (ret) {
          if (ret.data[0]) {
            this.url = ret.data[1].url
            this.loaded = true
          }
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
  iframe
    width: 100%
    height: 80vh
    border: 0
    border-radius: 10px
    box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.6)
</style>
